<template>
  <div>
    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="publishableKey"
      :line-items="lineItems"
      :success-url="successURL"
      :cancel-url="cancelURL"
      :customerEmail="email"
      :expires-at="expires_at"
      @loading="v => loading = v"
    />
  </div>
</template>

<script>
import { StripeCheckout } from '@vue-stripe/vue-stripe';

export default {
  components: {
    StripeCheckout,
  },
  data () {
    // this.publishableKey = 'pk_test_51JaeU5Glo9G2B2aGkozg5mX8Ft3ZDAofdg0y1MQCjaqXdJaRhsPdi5h8MNwrB0nM5fmuuFdvZplOQTP9IDmdS84a006qf6o7j8'; //テスト環境
    this.publishableKey = 'pk_live_51JaeU5Glo9G2B2aGIdhJFPust7S7kIVq4YwN7ny97RsXmAa6ckYnYshuT1ozrliR1nTPrdHzYI0ONC658SfOkiYB00LYFXzqHw'; //本番環境
    return {
      loading: false,
      lineItems: [
        {
          // price: null, // The id of the one-time price you created in your Stripe dashboard
          // price: 'price_1Ji7A2Glo9G2B2aGZCxueFuB',
          // price: 'price_1Jh1QBGlo9G2B2aGCn3mDlXx',
          price: null,
          quantity: 1,
        },
      ],
      successURL: 'https://salonpayments.officereiko.jp/?process=Sucess',
      // successURL: 'http://localhost:8080/Success/', //テスト環境
      cancelURL: 'https://officereiko.jp/',
      email:null,
      // email: "inscriptionnight@gmail.com",
      hash:null,
      process:null,
      expires_at:null,
    };
  },
  methods: {
    async querySet(){
      this.process = this.$route.query.process;
      console.log('process',this.process);
      if(this.$route.query.process){
        this.$router.push('/Success');
      }
      console.log(this.lineItems);
      this.email = await this.$route.query.email;
      await localStorage.setItem('email',this.email);
      this.hash = await this.$route.query.hash;
      await localStorage.setItem('hash',this.hash);
      this.lineItems[0].price = await this.$route.query.price;
      console.log(this.email);
      this.expires_at = await this.$route.query.time;
      console.log(this.expires_at);
      this.submit();
    },
    submit () {
      // You will be redirected to Stripe's secure checkout page
      this.$refs.checkoutRef.redirectToCheckout();
    },
  },
  created(){
    
    // this.submit();
    // this.email = 'info@infom.jp';

  },
  mounted(){
    this.querySet();
    // setTimeout(this.submit,5000);
  }
};
</script>