<template>
  <h1>リダイレクト中です...</h1>
</template>

<script>
// import axios from 'axios'; axiosだとcorsが解決出来なかった
export default {
  data(){
    return{
      email: null,
      hash: null,
    }
  },
  created(){
    console.log(this.$route);
    this.email = localStorage.getItem('email');
    this.hash = localStorage.getItem('hash');
    localStorage.removeItem('email');
    localStorage.removeItem('hash');
  },
  mounted(){
    const data = {
      email: this.email,
      hash: this.hash,
    };
    fetch('https://script.google.com/macros/s/AKfycbwh-GiSSqgjAXLIs6eYtVHLKd3FhJkuCCRxeXVG-U4lqZICVnH4P5rbFRFl1wyVY1iy/exec',{
      method: 'POST',
      mode: 'no-cors',
      body:JSON.stringify(data)}
      ).then(res => {
        console.log(res);
        location.href='https://officereiko.jp/salon-tyankyou/';
      })
  }
}
</script>